//#region imports
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonProductComponent, MultipleInsertionComponent, CashInComponent, CashOutComponent, PriceConsultationComponent, ChangeTicketComponent, SalesOfTheDayAndReturnsComponent } from './modals';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { GenericGridComponent } from '../../../common/components/generic-grid/generic-grid.component';
import { LoadingService } from '../../../common/components/loading/loading.service';
import { PaymentMethodSelectorComponent } from '../../../common/components/payment-method-selector/payment-method-selector.component';
import { AlertifyService } from '../../../common/services/alertify.service';
import { AuthenticationService } from '../../../interceptors/authentication.service';
import { CurrencyVM, LoginResponse, MovementVM, OperationResult, PaymentMethodVM, ProductToSale, ProductVM, SaleDetailsVM, SaleVM, SavedTicketVM, ShiftVM, TicketReferenceVM, TicketVM } from '../../../models/view-models';
import { PaymentMethodService } from '../../../services/payment-method.service';
import { ProductsService } from '../../../services/products.service';
import { SalesService } from '../../../services/sales.service';
import { MovementsService } from '../../../services/movements.service';
import { TicketsService } from '../../../services/tickets.service';
import { TicketGeneratorService } from '../../../services/ticket-generator.service';
import { Ticket } from '../../../models/LocalModels';
import { environment } from '../../../../environments/environment';
import { LocalData } from '../../../models/LocalData';
import { CustomerSelectorComponent } from '../../../common/components/customer-selector/customer-selector.component';
import { Router } from '@angular/router';
import { TicketType } from '../../../models/TicketType';
import { SaleStatus } from '../../../models/SaleStatus';
import { ExitComponent } from './modals/exit/exit.component';
import { ShiftService } from '../../../services/shift.service';
import { SaleErrorDialogComponent } from './modals/sale-error-dialog/sale-error-dialog.component';
import { ActionPermissionValidatorService } from '../../../services/action-permission-validator.service';
import { ScaleProduct } from '../../../models/ComponentModels';
import { ProductSearchComponent } from '../../../common/components/product-search/product-search.component';
import { SavedTicketService } from '../../../services/saved-ticket.service';
import { CustomValidators } from '../../../common/validators/CustomValidators';
import { MultiplePaymentMethodComponent } from '../../../common/components/multiple-payment-method/multiple-payment-method.component';
import { AuthorizationWindowComponent } from '../../../common/components/authorization-window/authorization-window.component';
import { ActionPermissionCode } from '../../../models/ActionPermissionCode';
import { CurrencyService } from '../../../services/currency.service';
import { PaymentMethodButtonSelectorComponent } from '../../../common/components/payment-method-button-selector/payment-method-button-selector.component';
import { SearchProductComponent } from '../../../common/components/search-product/search-product.component';
import { PrintTicket } from '../../../common/helpers/printer';
import { SavedTicketsComponent } from '../../../common/components/saved-tickets/saved-tickets.component';
//#endregion

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss']
})
export class SaleComponent implements OnInit, AfterViewInit, OnDestroy {

  activeShift: ShiftVM;
  hideContent: boolean = true;
  todayDate: Date = new Date;

  hasProducts: boolean = false;

  ticketId: string = "";
  ticketVM: TicketVM;

  userId: string = "";
  userName: string = "";

  products: ProductToSale[] = [];
  searchedProduct: ProductToSale;


  scaleProduct: ScaleProduct;
  detailsForm: FormGroup;
  newProductForm: FormGroup;

  @ViewChild("quantity") quantity: ElementRef;
  @ViewChild("newProductQuantity") newProductQuantity: ElementRef;

  @ViewChild("grid", { read: GenericGridComponent, static: true })
  public grid: GenericGridComponent;

  minQuantity: number = 1;
  total: number = 0;
  disabledInput: boolean = false;

  detailsDialogOpen: boolean = false;
  editableProduct: ProductToSale;

  newProductDialogOpen: boolean = false;
  newProduct: ProductToSale;

  chargeDialogOpen: boolean = false;
  @ViewChild("paymentMethodSelector", { read: PaymentMethodButtonSelectorComponent, static: false })
  public paymentMethodSelector: PaymentMethodButtonSelectorComponent;
  paymentMethods: PaymentMethodVM[] = [];
  @ViewChild("customerSelector", { read: CustomerSelectorComponent, static: false })
  public customerSelector: CustomerSelectorComponent;
  chargeForm: FormGroup;
  disablePaid: boolean = true;
  @ViewChild("paid") paid: ElementRef;
  @ViewChild("reference") reference: ElementRef;
  change: number = 0;
  hideReferenceInput: boolean = true;
  hideCustomerSelector: boolean = true;
  isPaid: boolean = true;

  // Modals
  @ViewChild("commonProductModal", { read: CommonProductComponent })
  public commonProductModal: CommonProductComponent;

  @ViewChild("multipleInsertionModal", { read: MultipleInsertionComponent })
  public multipleInsertionModal: MultipleInsertionComponent;

  @ViewChild("priceConsultationModal", { read: PriceConsultationComponent })
  public priceConsultationModal: PriceConsultationComponent;

  @ViewChild("cashInModal", { read: CashInComponent })
  public cashInModal: CashInComponent;

  @ViewChild("cashOutModal", { read: CashOutComponent })
  public cashOutModal: CashOutComponent;

  @ViewChild("changeTicketModal", { read: ChangeTicketComponent })
  public changeTicketModal: ChangeTicketComponent;
  selectedTicket: Ticket;

  @ViewChild("SotDWindow", { read: SalesOfTheDayAndReturnsComponent })
  public SotDWindow: SalesOfTheDayAndReturnsComponent;

  @ViewChild("exitConfirm", { read: ExitComponent, static: false })
  public exitConfirm: ExitComponent;

  @ViewChild("saleErrorModal", { read: SaleErrorDialogComponent })
  public saleErrorModal: SaleErrorDialogComponent;

  cashInButtonDisabled: boolean = false;
  cashOutButtonDisabled: boolean = false;

  @ViewChild("productSearchCmp", { read: SearchProductComponent })
  public productSearchCmp: SearchProductComponent;

  chargeInProcess: boolean = false;
  @ViewChild("multiplePay", { read: MultiplePaymentMethodComponent })
  public multiplePay: MultiplePaymentMethodComponent;
  hideMultiplePay: boolean = true;
  readOnlyAmount: boolean = false;

  @ViewChild("authorizationWindow", { read: AuthorizationWindowComponent })
  public authorizationWindow: AuthorizationWindowComponent;

  //@ViewChild("ticketsModal", { read: TicketsDialogComponent })
  //public ticketsModal: TicketsDialogComponent;

  collapseSaleActionButtons: boolean = true;
  collapseSavedTickets: boolean = true;
  collapseMoreOptions: boolean = true;

  isCurrencyPaymentMethod: boolean = false;
  currencyCode: string = "";
  currencyTotal: number = 0;
  currencyValue: number = 0;
  currencyChange: number = 0;

  isMultiplePaymentMethod: boolean = false;
  @ViewChild("chargeButton") chargeButton!: ElementRef;

  @ViewChild(SavedTicketsComponent) savedTicketsCmp: SavedTicketsComponent;

  constructor(private productsService: ProductsService,
    private paymentMethodService: PaymentMethodService,
    private currencyService: CurrencyService,
    private salesService: SalesService,
    private movementService: MovementsService,
    private authService: AuthenticationService,
    private actionValidatorService: ActionPermissionValidatorService,
    private ticketsService: TicketsService,
    private ticketGenerator: TicketGeneratorService,
    private savedTicketService: SavedTicketService,
    private alertify: AlertifyService,
    private decimalPipe: DecimalPipe,
    private fb: FormBuilder,
    private router: Router,
    private shiftService: ShiftService,
    private loading: LoadingService) {

    let usr: LoginResponse = this.authService.SessionUserValue();
    this.userId = usr.UUID;
    this.userName = usr.User;

    this.getTicketReference();

    //this.activeShift = JSON.parse(localStorage.getItem(LocalData.ACTIVE_SHIFT));
    //this.hideContent = this.shiftService.validateActiveShift(this.todayDate);
  }

  ngOnDestroy(): void {
    if (this.grid.items.length > 0) {
      if (confirm("¿Desea guardar el ticket actual?")) {
        this.saveTicketEvent();
      }
    }
  }

  ngOnInit(): void {
    this.loadPaymentMethods();
    this.setGridOptions();
    this.cashInButtonDisabled = this.actionValidatorService.cashIn();
    this.cashOutButtonDisabled = this.actionValidatorService.cashOut();

  }

  ngAfterViewInit(): void {
    this.focusOnCodeInput();
  }

  getTicketReference() {
    this.loading.show();
    //this.ticketGenerator.getSaleAndTicketReference(this.shiftService.getActiveShiftId())
    this.ticketGenerator.getUserSaleAndTicketReference(this.shiftService.getActiveShiftId(), this.userId)
      .subscribe((response: TicketVM) => {
        if (response) {
          this.ticketVM = response;
          this.ticketId = response.Id.toString();
        }
        this.loading.hide();
      }, (err) => {
        this.loading.hide();
        console.log(err);
      });
  }

  loadPaymentMethods() {
    this.paymentMethodService.loadAndSaveData();
  }

  focusOnCodeInput() {
    this.productSearchCmp.focusOnInput();
  }

  codeInputBlur() {
    this.productSearchCmp.inputBlur();
  }

  focusOnReferenceInput() {
    setTimeout(() => {
      this.reference.nativeElement.focus();
    }, 0);
  }

  focusOnQuantityInput() {
    setTimeout(() => {
      this.quantity.nativeElement.focus();
      this.quantity.nativeElement.select();
    }, 0);
  }

  focusOnPaidInput() {
    setTimeout(() => {
      this.paid.nativeElement.focus();
    }, 0);
  }

  setGridOptions() {
    this.grid.setData(this.products);
    this.grid.options.toolbarTitle = "Productos";
    this.grid.options.paging = false;
    this.grid.options.navigable = true;
    this.grid.options.hideActionButtons = false;
    this.grid.options.showDetailsButton = false;
    this.grid.options.showEditButton = false;
    this.grid.options.showDeleteButton = true;
    this.grid.options.selectableCheckbox = true;
    this.grid.options.showToolbar = false;
    this.grid.options.rowClick = true;
    this.grid.columns = [
      { field: "Code", title: "Código" },
      { field: "Name", title: "Nombre" },
      { field: "Quantity", title: "Cantidad" },
      { field: "UnitName", title: "Unidad" },
      { field: "Existence", title: "Existencia" },
      { field: "Price", title: "Precio", currencyType: true },
      { field: "Total", title: "Total", currencyType: true },
    ];
    this.grid.setGridOptions();
  }

  isScaleProduct(productCode: string): boolean {
    if (productCode.length === 13) {
      this.scaleProduct = this.decomposeCode(productCode);
      if (this.scaleProduct.StartingCharacter === '2'
        && (this.scaleProduct.EndingCharacter === '5' || this.scaleProduct.EndingCharacter === '6')) {
        return true;
      }
      //return false;
      return true;
    }
    return false;
  }

  decomposeCode(code: string): ScaleProduct {
    return {
      StartingCharacter: code.substring(0, 1),
      ProductCode: code.substring(1, 7),
      Weight: parseFloat(code.substring(8, 12)),
      EndingCharacter: code.substring(12, 13)
    };
  }

  addScaleProduct(newProduct: ProductToSale) {
    if (newProduct.Group) {
      var exists: ProductToSale = this.grid.items.find(item => item.UUID === newProduct.UUID);
      if (exists) {
        exists.Quantity = exists.Quantity + newProduct.Quantity;
        exists.Total = exists.RetailPrice * exists.Quantity;
        this.grid.updateRowByUUID(exists);
      } else {
        this.grid.addRow(newProduct);
      }
    } else {
      this.grid.addRow(newProduct);
    }

    this.calculateTotal();
  }

  addProduct(product: ProductToSale) {
    var exists: ProductToSale = this.grid.items.find(item => item.UUID === product.UUID);
    if (exists) {
      exists.Quantity = exists.Quantity + product.Quantity;
      exists.Total = exists.RetailPrice * exists.Quantity;
      this.grid.updateRowByUUID(exists);
    } else {
      this.grid.addRow(product);
    }
    this.calculateTotal();
  }

  calculateTotal() {
    let _total = 0;
    this.hasProducts = this.grid.items.length > 0;
    this.grid.items.forEach(item => {
      _total = _total + item.Total;
    });
    this.total = _total;
  }

  editProduct() {
    if (this.isDetailsValidForm()) {
      this.editableProduct.Quantity = this.detailsForm.value["Quantity"];
      this.editableProduct.RetailPrice = this.detailsForm.value["RetailPrice"];
      this.grid.updateRowByUUID(this.editableProduct);
      this.calculateTotal();
      this.closeDetailsDialog();
    }
  }

  deleteProductFromGrid(prod: ProductToSale) {
    this.alertify.confirm(`Eliminar producto ${prod.Name}`,
      () => {
        this.editableProduct = prod;
        this.deleteProduct();
      });
  }

  deleteProduct() {
    this.grid.deleteRow(this.editableProduct.UUID);
    this.calculateTotal();
    this.closeDetailsDialog();
  }

  select(product: ProductToSale) {
    this.editableProduct = product;
    this.detailsDialogOpen = true;
    this.initDetailsForm();
  }

  initDetailsForm() {
    this.detailsForm = this.fb.group({
      Quantity: [this.editableProduct.Quantity, [Validators.required, Validators.min(0)]],
      RetailPrice: [this.setFormatNumber(this.editableProduct.Price), [Validators.required, Validators.min(0)]],
    });
    this.onDetailsFormChanges();
    this.focusOnQuantityInput();
  }

  setFormatNumber(value: any) {
    let tmp = this.decimalPipe.transform(value, '1.2-2', 'mx');
    tmp = tmp.replace(',', '');
    return tmp;
  }

  onDetailsFormChanges(): void {
    this.detailsForm.valueChanges
      .subscribe(val => {
        this.editableProduct.Total = val.Quantity * val.RetailPrice;
      });
  }

  isDetailsValidForm(): boolean { return this.detailsForm.valid; }

  isChargeValidForm(): boolean {
    if (this.paymentMethodSelector) {
      if (this.paymentMethodSelector.getItemCode() === LocalData.PAYMENT_METHOD_CASH
        || this.paymentMethodSelector.getItemCode() === LocalData.PAYMENT_METHOD_VOUCHER
        || this.isCurrencyPaymentMethod) {
        return this.chargeForm.valid
          && this.total > 0
          && this.change >= 0
          && this.grid.items.length > 0
          && this.isPaidAmountValid();
      } else if (this.paymentMethodSelector.getItemCode() === LocalData.PAYMENT_METHOD_CARD
        || this.paymentMethodSelector.getItemCode() === LocalData.PAYMENT_METHOD_EBANK) {
        return this.chargeForm.valid
          && this.total > 0
          && this.change >= 0
          && this.grid.items.length > 0
          && this.isPaidAmountValid();
      } else if (this.paymentMethodSelector.getItemCode() === LocalData.PAYMENT_METHOD_CREDIT) {
        return this.total > 0
          && this.grid.items.length > 0
          && this.customerCreditValid();
      } else if (this.paymentMethodSelector.getItemCode() === LocalData.PAYMENT_METHOD_MULTIPLE) {
        return this.chargeForm.valid
          && this.total > 0
          && this.change >= 0
          && this.grid.items.length > 0
          && this.isPaidAmountValid()
          && this.multiplePay.isMultiplePaymentMethodFormValid();
      }
    }
    return false;
  }

  customerCreditValid(): boolean {
    if (!this.hideCustomerSelector) {
      return this.customerSelector.isValidForm();
    } else {
      return true;
    }
  }

  closeDetailsDialog = () => {
    this.detailsDialogOpen = false;
    this.focusOnCodeInput();
  }

  openChargeDialog() {
    if (this.total > 0
      && this.grid.items.length > 0) {
      this.chargeDialogOpen = true;
      this.currencyCode = "";
      this.initChargeForm();
      this.hideReferenceInput = true;
      this.hideCustomerSelector = true;
      this.hideMultiplePay = true;
      this.readOnlyAmount = false;
      this.isMultiplePaymentMethod = false;
    }

  }

  initChargeForm() {
    this.chargeForm = this.fb.group({
      Paid: ['', [Validators.required]],
      BankReference: ['', [CustomValidators.removeSpecialCharacters]],
    });
    this.change = 0;
    this.onChargeFormChanges();
    this.disablePaid = false;
    this.focusOnPaidInput();



  }

  onChargeFormChanges() {
    this.chargeForm.valueChanges
      .subscribe(value => {
        if (this.hideCustomerSelector) {
          this.change = value.Paid - this.total;
        }
        if (this.isCurrencyPaymentMethod) {
          this.currencyChange = value.Paid - this.currencyTotal;
          this.change = (value.Paid * this.currencyValue) - this.total;
        }
      });
  }

  getSaleDetails(): SaleDetailsVM[] {
    let details: SaleDetailsVM[] = [];

    this.grid.items.forEach((item: ProductToSale) => {
      details.push({
        ProductId: item.UUID,
        ProductName: item.Name,
        UsesInventory: item.UsesInventory,
        Quantity: item.Quantity,
        UnitId: item.UnitId,
        UnitName: item.UnitName,
        CategoryId: item.CategoryId,
        CategoryName: item.CategoryName,
        UnitPrice: item.Price,
        TotalPrice: item.Quantity * item.Price,
        ShiftId: this.shiftService.getActiveShiftId(),
      });
    });

    return details;
  }

  paymentMethodSelect(method: PaymentMethodVM) {
    this.isMultiplePaymentMethod = false;
    if (method.IsCurrency) {
      this.paymentMethodIsCurrency(method.Code);
    }
    this.hideReferenceInput = true;
    this.hideMultiplePay = true;
    this.readOnlyAmount = false;

    if (method.Code === LocalData.PAYMENT_METHOD_CASH
      || method.Code === LocalData.PAYMENT_METHOD_VOUCHER) {
      this.focusOnPaidInput();
    }
    if (method.Code === LocalData.PAYMENT_METHOD_CARD
      || method.Code === LocalData.PAYMENT_METHOD_EBANK) {
      this.hideReferenceInput = false;
    }
    this.hideCustomerSelector = method.Code !== LocalData.PAYMENT_METHOD_CREDIT;
    this.isPaid = this.hideCustomerSelector;
    this.disablePaid = !this.isPaid;

    if (method.Code === LocalData.PAYMENT_METHOD_CREDIT) {
      this.chargeForm.controls["Paid"].setValue('0');
    }

    if (method.Code === LocalData.PAYMENT_METHOD_CARD
      || method.Code === LocalData.PAYMENT_METHOD_EBANK) {
      this.chargeForm.controls["Paid"].setValue(this.total);
      this.focusOnReferenceInput();
    }

    if (method.Code === LocalData.PAYMENT_METHOD_MULTIPLE) {
      this.hideMultiplePay = false;
      this.readOnlyAmount = true;
      this.isMultiplePaymentMethod = true;
      this.multiplePay.focusOnCash();
    }
  }

  paymentMethodIsCurrency(code: string) {
    let currency: CurrencyVM = this.currencyService.getLocalCurrency(code);
    this.currencyValue = currency.Exchange;
    this.currencyTotal = this.total / currency.Exchange;
    this.currencyCode = currency.Code;
    this.isCurrencyPaymentMethod = true;
    this.focusOnPaidInput();

  }

  createCharge() {
    this.chargeInProcess = true;
    if (this.isChargeValidForm()) {
      this.loading.show();

      this.salesService.insert(this.generateSale())
        .subscribe((response: SaleVM) => {
          if (response) {            
            this.resetChargeDialog();
            this.openTicket(response.TicketUrl);
            this.getTicketReference();

            if (this.selectedTicket) 
              this.ticketsService.deleteTicket(this.selectedTicket);

            this.savedTicketsCmp.loadSavedTickets();
          } else {
            this.createCharge_error();
          }
          this.loading.hide();
          this.chargeInProcess = false;
        }, (err) => {
          this.loading.hide();
          this.chargeInProcess = false;
        });
    } else {
      this.chargeInProcess = false;
    }

  }

  private resetChargeDialog() {
    this.paymentMethodSelector.reset();
        this.closeChargeDialog();
        this.hideReferenceInput = true;
        this.hideCustomerSelector = true;
        this.hideMultiplePay = true;
        this.isCurrencyPaymentMethod = false;
        this.currencyValue = 0;
        this.currencyTotal = 0;
        this.currencyChange = 0;
    }

  private generateSale(): SaleVM {
    let newSale: SaleVM = Object.assign({
      UUID: this.ticketVM.SaleId,
      UserId: this.userId,
      Username: this.userName,
      PaymentMethodCode: this.paymentMethodSelector.getItemCode(),
      PaymentMethodName: this.paymentMethodSelector.getItemName(),
      Total: this.total,
      Change: this.change,
      CreateDate: new Date(),
      SaleDetails: this.getSaleDetails(),
      TicketId: this.ticketVM.Id,
      CustomerId: this.customerSelector.getItemId(),
      CustomerName: this.customerSelector.getItemFullName(),
      IsPaid: this.isPaid,
      Status: SaleStatus.Active,
      ShiftId: this.shiftService.getActiveShiftId()
    }, this.chargeForm.value);

    // if payment method is multiple
    if (newSale.PaymentMethodCode === LocalData.PAYMENT_METHOD_MULTIPLE) {
      newSale.MultiplePayments = this.multiplePay.getPayments(newSale.UUID);
      if (this.multiplePay.hasCreditPayment()) {
        newSale.CustomerId = this.multiplePay.getCustomerId();
        newSale.CustomerName = this.multiplePay.getCustomerName();
        newSale.Paid = this.multiplePay.getPaidWithNoCredit();
        newSale.Change = 0;
        newSale.IsPaid = false;
      }
    }

    // if payment method is currency
    if (this.isCurrencyPaymentMethod) {
      newSale.Paid = newSale.Paid * this.currencyValue;
    }
    return newSale;
  }



  isPaidAmountValid(): boolean {
    return parseFloat(this.chargeForm.controls["Paid"].value) > 0;
  }

  clearItems() {
    this.products = [];
    this.hasProducts = this.products.length > 0;
    this.setGridOptions();
    this.total = 0;
  }

  closeChargeDialog() {
    this.clearItems();
    this.change = 0;
    this.chargeDialogOpen = false;
    this.hasProducts = false;
    this.focusOnCodeInput();
  }

  onCloseChargeDialog() {
    this.chargeDialogOpen = false;
    this.focusOnCodeInput();
  }

  createCharge_error() {
    this.loading.hide();
    this.hideReferenceInput = true;
    this.hideCustomerSelector = true;
    this.chargeDialogOpen = false;
    let err: OperationResult<any> = JSON.parse(localStorage.getItem(LocalData.SALE_ERROR));
    if (err.StatusCode === 409) {
      this.saleErrorModal.openDialog(this.customerSelector.getItemId());
    }
  }

  onSaleErrorDialogClose() {
  }


  // Shortcuts events
  SC_Charge = () => this.openChargeDialog();
  SC_CommonProduct = () => this.commonProductEvent();
  SC_MultipleInsertion = () => this.multipleInsertionEvent();
  SC_WholesalePrice = () => this.wholesaleEvent();
  SC_CashIn = () => this.cashInEvent();
  SC_CashOut = () => this.cashOutEvent();
  SC_PriceConsultation = () => this.priceConsultationEvent();

  SC_SaveTicket = () => this.saveTicketEvent();
  SC_DeleteCurrentTicket = () => this.deleteTicketEvent();
  SC_ChangeTicket = () => this.ChangeTicketEvent();
  SC_ReprintLastTicket = () => this.openLastTicketEvent();
  SC_SalesOfTheDayAndReturns = () => this.SOTDAREvent();
  SC_OpenTicketsListDialog = () => this.openTicketsEvent();

  // Buttons events

  commonProductEvent() {
    this.codeInputBlur();
    this.commonProductModal.openDialog();
  }

  multipleInsertionEvent() {
    this.codeInputBlur();
    this.multipleInsertionModal.openDialog();
  }

  priceConsultationEvent() {
    this.codeInputBlur();
    this.priceConsultationModal.openDialog();
  }

  cashInEvent() {
    if (!this.actionValidatorService.cashIn()) {
      this.codeInputBlur();
      this.cashInModal.openDialog(this.userId, this.userName);
    }
  }

  cashOutEvent() {
    if (!this.actionValidatorService.cashOut()) {
      this.codeInputBlur();
      this.cashOutModal.openDialog(this.userId, this.userName);
    }
  }

  wholesaleEvent() {
    if (this.detailsDialogOpen) {
      if (this.editableProduct.UseRetailPrice) {
        this.detailsForm.controls["RetailPrice"].setValue(this.editableProduct.WholesalePrice);
        this.editableProduct.UseRetailPrice = false;
      } else {
        this.detailsForm.controls["RetailPrice"].setValue(this.editableProduct.RetailPrice);
        this.editableProduct.UseRetailPrice = true;
      }
    } else {
      this.grid.getSelectedRows().forEach(item => {
        let ep: ProductToSale = this.grid.items.find(p => p.UUID === item);
        if (ep.UseRetailPrice) {
          ep.Price = ep.WholesalePrice;
          ep.Total = ep.Quantity * ep.Price;
          ep.UseRetailPrice = false;
          this.grid.updateRowByUUID(ep);
        } else {
          ep.Price = ep.RetailPrice;
          ep.Total = ep.Quantity * ep.Price;
          ep.UseRetailPrice = true;
          this.grid.updateRowByUUID(ep);
        }
      });
      this.calculateTotal();
    }
  }

  deleteTicketEvent() {
    if (this.grid.items.length > 0) {

      this.alertify.confirm("Eliminar ticket actual", () => {

        if (this.selectedTicket) {
          this.loading.show();
          this.savedTicketService.delete(this.selectedTicket.TicketId)
            .subscribe((response: boolean) => {
              if (response) {
                this.ticketsService.deleteTicket(this.selectedTicket);
                this.clearItems();
                this.getTicketReference();
                this.savedTicketsCmp.loadSavedTickets();
                this.loading.hide();
                this.focusOnCodeInput();
              }
            }, (err) => {
              this.loading.hide();
              console.log(err);
            });

        } else {
          this.clearItems();
          this.focusOnCodeInput();
        }

      });
    }
  }

  createTicketInfo(): Ticket {
    return {
      Date: new Date(),
      UserId: this.userId,
      Products: this.grid.items,
      Total: this.total,
      TicketId: this.ticketVM.Id,
      SaleId: this.ticketVM.SaleId,
      ShiftId: this.shiftService.getActiveShiftId()
    };
  }

  saveTicketEvent() {
    if (this.grid.items.length > 0) {
      const ticket: Ticket = this.createTicketInfo();
      const savedTicket: SavedTicketVM = {
        TicketId: ticket.TicketId,
        JsonInfo: JSON.stringify(ticket),
        CreateDate: ticket.Date
      };
      this.loading.show();
      this.savedTicketService.save(savedTicket)
        .subscribe((response: SavedTicketVM) => {
          if (response) {
            this.getTicketReference();
            this.savedTicketsCmp.loadSavedTickets();
            this.clearItems();
            this.change = 0;
            this.hasProducts = false;
            this.focusOnCodeInput();
            this.loading.hide();
          }
        }, (err) => {
          this.loading.hide();
          console.log(err);
        });
    }
  }

  ChangeTicketEvent() {
    this.codeInputBlur();
    this.changeTicketModal.openDialog();
  }

  SOTDAREvent() {
    this.router.navigate(['/home/pos/sales-of-the-day']);
    //this.code.nativeElement.blur();
    //this.loading.show();
    //this.salesService.getTodaySales()
    //  .subscribe((response: SaleVM[]) => {
    //    if (response) {
    //      this.SotDWindow.sales = response;
    //    }
    //    this.SotDWindow.openWindow();
    //    this.loading.hide();
    //  }, (err) => {
    //      this.loading.hide();
    //  });
  }


  // Common product modal events
  commonProductEmits(product: ProductToSale) {
    this.loading.show();
    this.productsService.getNewGuid()
      .subscribe((response: string) => {
        if (response) {
          product.UUID = response;
          this.addProduct(product);
          this.focusOnCodeInput();
          this.loading.hide();
        }
      });
  }

  commonProductModalClose = () => this.focusOnCodeInput();


  // Multiple insertion modal events
  multipleInsertionSearchCode(value: string) {
    this.multipleInsertionModal.disabledInput = true;
    this.loading.show();
    this.productsService.getByCode(value)
      .subscribe((response: ProductVM) => {
        if (response) {
          this.multipleInsertionModal.product = {
            UUID: response.UUID,
            Code: response.Code,
            CategoryId: response.CategoryId,
            CategoryName: response.CategoryName,
            UnitId: response.UnitId,
            UnitName: response.UnitName,
            Name: response.Name,
            RetailPrice: response.RetailPrice,
            WholesalePrice: response.WholesalePrice,
            Price: response.RetailPrice,
            UsesInventory: response.UsesInventory,
            Existence: response.Quantity,
            Quantity: this.minQuantity,
            Total: response.RetailPrice * this.minQuantity
          };
          this.multipleInsertionModal.showProductDetails = true;
          this.multipleInsertionModal.focusOnQuantityInput();
          this.multipleInsertionModal.onFormChanges();
        } else {
          this.alertify.warning("No se encontró el producto");
        }
        this.multipleInsertionModal.initCodeForm();
        this.multipleInsertionModal.disabledInput = false;
        this.loading.hide();
      });
  }

  multipleInsertionEmits(product: ProductToSale) {
    this.addProduct(product);
  }


  // Price consultation modal event
  priceConsultationSearchCode(value: string) {
    this.priceConsultationModal.disabledInput = true;
    this.loading.show();
    this.productsService.getByCode(value)
      .subscribe((response: ProductVM) => {
        if (response) {
          this.priceConsultationModal.product = {
            UUID: response.UUID,
            Code: response.Code,
            CategoryId: response.CategoryId,
            CategoryName: response.CategoryName,
            UnitId: response.UnitId,
            UnitName: response.UnitName,
            Name: response.Name,
            RetailPrice: response.RetailPrice,
            WholesalePrice: response.WholesalePrice,
            Price: response.RetailPrice,
            UsesInventory: response.UsesInventory,
            Existence: response.Quantity,
            Quantity: this.minQuantity,
            Total: response.RetailPrice * this.minQuantity,
            UseRetailPrice: true
          };
          this.priceConsultationModal.showProductDetails = true;
          this.priceConsultationModal.focusOnCloseButton();
        } else {
          this.alertify.warning("No se encontró el producto");
        }
        this.priceConsultationModal.initDialogForm();
        this.priceConsultationModal.disabledInput = false;
        this.loading.hide();
      });
  }

  multipleInsertionModalClose = () => this.focusOnCodeInput();



  // CashIn modal events
  cashInEmits(movement: MovementVM) {
    if (this.actionValidatorService.requiresAuthorization(ActionPermissionCode.CASH_IN)
      && !this.actionValidatorService.canExecuteAction(ActionPermissionCode.CASH_IN)) {
      this.authorizationWindow.openAuthorizationWindowForMovement(movement);
    } else {
      if (!this.cashInButtonDisabled) {
        this.loading.show();
        this.movementService.createCashInMovement(movement)
          .subscribe((response: MovementVM) => {
            if (response) {
              this.openTicket(response.TicketUrl);
              this.cashInModal.showDialog = false;
              this.loading.hide();
              this.focusOnCodeInput();
            }
          }, (err) => {
            this.loading.hide();
          });
      }
    }
  }

  cashInModalClose = () => this.focusOnCodeInput();


  // CashOut modal events
  cashOutEmits(movement: MovementVM) {
    if (this.actionValidatorService.requiresAuthorization(ActionPermissionCode.CASH_OUT)
      && !this.actionValidatorService.canExecuteAction(ActionPermissionCode.CASH_OUT)) {
      this.authorizationWindow.openAuthorizationWindowForMovement(movement);
    } else {
      if (!this.cashOutButtonDisabled) {
        this.loading.show();
        this.movementService.createCashOutMovement(movement)
          .subscribe((response: MovementVM) => {
            if (response) {
              this.openTicket(response.TicketUrl);
              this.cashOutModal.showDialog = false;
              this.loading.hide();
              this.focusOnCodeInput();
            }
          }, (err) => {
            this.loading.hide();
          });
      }
    }
  }

  cashOutModalClose = () => this.focusOnCodeInput();


  // Change ticket modal events
  changeTicketEmits(ticket: Ticket) {
    this.selectedTicket = ticket;
    this.products = ticket.Products;
    this.hasProducts = this.products.length > 0;
    this.total = ticket.Total;
    this.ticketVM = {
      Id: ticket.TicketId,
      Type: TicketType.SALE,
      SaleId: ticket.SaleId,
      ShiftId: ticket.ShiftId,
    };
    this.ticketId = this.ticketVM.Id.toString();
    this.setGridOptions();
    if (!this.collapseSavedTickets)
      this.collapseSavedTickets = true;
    this.focusOnCodeInput();
  }

  changeTicketModalClose = () => this.focusOnCodeInput();


  // Sales of the day and returns window events

  SotDWindowClose = () => this.focusOnCodeInput();

  openTicket(filename: string): void {
    const url = `${environment.ticketsUrl}/${filename}`;
    window.open(url, '_blank');
  }

  openAddProductDialog(product: ProductToSale) {
    this.newProduct = product;
    this.newProductDialogOpen = true;
    if (this.newProduct.UsesInventory) {
      this.initNewProductFormWithExistence();
    } else {
      this.initNewProductFormWithoutExistence();
    }
  }

  initNewProductFormWithExistence() {
    this.newProductForm = this.fb.group({
      Quantity: [this.newProduct.Quantity, [Validators.required, CustomValidators.greaterThanZero, Validators.max(this.newProduct.Existence)]],
      RetailPrice: [this.newProduct.Price, [Validators.required, Validators.min(0)]],
    });
    this.onNewProductChanges();
    this.focusOnNewProductQuantityInput();
  }

  initNewProductFormWithoutExistence() {
    this.newProductForm = this.fb.group({
      Quantity: [this.newProduct.Quantity, [Validators.required, CustomValidators.greaterThanZero]],
      RetailPrice: [this.newProduct.Price, [Validators.required, Validators.min(0)]],
    });
    this.onNewProductChanges();
    this.focusOnNewProductQuantityInput();
  }

  focusOnNewProductQuantityInput() {
    setTimeout(() => {
      this.newProductQuantity.nativeElement.focus();
      this.newProductQuantity.nativeElement.select();
    }, 0);
  }

  onNewProductChanges(): void {
    this.newProductForm.valueChanges
      .subscribe(val => {
        this.newProduct.Total = val.Quantity * val.RetailPrice;
      });
  }

  isNewProductValidForm(): boolean { return this.newProductForm.valid; }

  closeNewProductDialog = () => {
    this.newProductDialogOpen = false;
    this.disabledInput = false;
    this.focusOnCodeInput();
  }

  addNewProduct() {
    if (this.isNewProductValidForm()) {
      this.newProduct.Quantity = this.newProductForm.value["Quantity"];
      this.newProduct.Price = this.newProductForm.value["RetailPrice"];

      if (this.newProduct.Group) {
        var exists: ProductToSale = this.grid.items.find(item => item.UUID === this.newProduct.UUID);
        if (exists) {
          exists.Quantity = exists.Quantity + this.newProduct.Quantity;
          exists.Total = exists.Price * exists.Quantity;
          this.grid.updateRowByUUID(exists);
        } else {
          this.grid.addRow(this.newProduct);
        }
      } else {
        this.grid.addRow(this.newProduct);
      }

      this.calculateTotal();

      this.closeNewProductDialog();
    }
  }

  openLastTicketEvent() {
    this.loading.show();
    this.salesService.getLastTicket()
      .subscribe((response: TicketReferenceVM) => {
        if (response) {
          this.openTicket(response.Filename);
        }
        this.loading.hide();
      });
  }

  // exit dialog confirmation
  exitConfirmation() {
    this.saveTicketEvent();
  }

  exitCancel() { }

  SC_RemoveLastProduct() {
    if (this.hasProducts) {
      let last: ProductToSale = this.getLastProductAdded();
      this.deleteProductFromGrid(last);
    }
  }

  getLastProductAdded(): ProductToSale {
    let idx: number = this.grid.items.length - 1;
    return this.grid.items[idx];
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    const ESCAPE_KEYCODE = 27;
    if (event.keyCode === ESCAPE_KEYCODE) {
      if (this.detailsDialogOpen) {
        this.detailsDialogOpen = false;
      }
      if (this.chargeDialogOpen) {
        this.chargeDialogOpen = false;
      }
      if (this.newProductDialogOpen) {
        this.newProductDialogOpen = false;
      }
      if (this.commonProductModal.showDialog) {
        this.commonProductModal.showDialog = false;
      }
      if (this.multipleInsertionModal.showDialog) {
        this.multipleInsertionModal.showDialog = false;
      }
      if (this.priceConsultationModal.showDialog) {
        this.priceConsultationModal.showDialog = false;
      }
      if (this.cashInModal.showDialog) {
        this.cashInModal.showDialog = false;
      }
      if (this.cashOutModal.showDialog) {
        this.cashOutModal.showDialog = false;
      }
      if (this.changeTicketModal.showDialog) {
        this.changeTicketModal.showDialog = false;
      }
      //if (this.ticketsModal.showDialog) {
      //  this.ticketsModal.showDialog = false;
      //}
      this.focusOnCodeInput();
    }
  }

  onMultiplePaymentChange(value: number) {
    this.chargeForm.controls["Paid"].setValue(value);
  }

  paidAmountReadonly(): boolean {
    return false;
  }

  // authorization window events
  authorizedMovementCreated(value: boolean) {
    if (value) {
      this.cashInModal.showDialog = false;
      this.cashOutModal.showDialog = false;
    }
  }

  openTicketsEvent() {
    //this.ticketsModal.openDialog();
  }

  ticketsModalClose = () => this.focusOnCodeInput();

  // sale action and saved tickets buttons

  csabClick() {
    this.collapseSaleActionButtons = !this.collapseSaleActionButtons;
    this.collapseSavedTickets = true;
    this.collapseMoreOptions = true;
  }

  cstbClick() {
    this.collapseSavedTickets = !this.collapseSavedTickets;
    this.collapseSaleActionButtons = true;
    this.collapseMoreOptions = true;
  }

  cmobClick() {
    this.collapseMoreOptions = !this.collapseMoreOptions;
    this.collapseSaleActionButtons = true;
    this.collapseSavedTickets = true;
  }

  focusOnChargeButton() {
    if (this.isChargeValidForm()) {
      this.chargeButton.nativeElement.focus();
    }
  }






}
